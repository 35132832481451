import React from "react";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { MdLocationPin } from "react-icons/md";

const Footer = () => {
  return (
    <div className="w-[100%] h-[100%] md:h-[527px] bg-black text-white flex ">
      <div className="md:w-[100%] h-[100%] grid md:grid-cols-10 grid-cols-1   p-10">
        <div className=" md:col-span-4 md:w-[100%] h-[527px] md:h-[100%] bg-black flex flex-col justify-between items-center">
          <h2 className=" w-[100%] h-[35%] text-pretty text-white text-[48px] font-[600] leading-[75px]">
            GET IN <span className="text-blue-600">TOUCH</span>{" "}
          </h2>
          <div className="w-[100%] h-[65%]  flex flex-col gap-6">
            <div className="flex items-center gap-3">
              <span className="flex-shrink-0 text-xl">
                <IoCall />
              </span>
              <span className="text-[24px] font-mono font-[300] leading-[37px]">
                {" "}
                
              </span>
            </div>

            <div className="flex items-center gap-3">
              <span className="flex-shrink-0 text-xl">
                <IoMdMail />
              </span>
              <span className="text-[24px] font-mono font-[300] leading-[37px]">
                {" "}
                info@clientincorporation.com
              </span>
            </div>

            <div className="flex items-center gap-3">
              <span className="flex-shrink-0  text-2xl ">
                <MdLocationPin />
              </span>
              <span className="text-[24px]  font-mono font-[300] leading-[37px]">
                {" "}
                Gurugram- 122001, IN
              </span>
            </div>
          </div>
        </div>
        <div className=" md:col-span-6 md:w-[100%] h-[527px] md:h-[100%] bg-black flex flex-col items-center md:gap-8 gap-16">
          <div className=" w-[100%]  text-gray-500 font-[700] text-[32px]">
            Client Incorporation is the best place to increase your revenue by 3x.
          </div>
          <div className="w-[90%] ">
            <form className=" relative flex flex-col gap-10">
              <input
                type="text"
                placeholder="Name"
                className=" font-mono outline-none w-[100%] py-1 border-b border-b-gray-500 bg-inherit "
              />
              <div className="w-[100%] grid grid-cols-2 gap-5">
                <input
                  type="text"
                  placeholder="E-mail"
                  className="font-mono  outline-none w-[100%] py-1 border-b border-b-gray-500 bg-inherit "
                />
                <input
                  type="text"
                  placeholder="Subject"
                  className="font-mono  outline-none w-[100%] py-1 border-b border-b-gray-500 bg-inherit "
                />
              </div>

              <input
                type="text"
                placeholder="Message"
                className=" font-mono outline-none w-[100%] py-1 border-b border-b-gray-500 bg-inherit "
              />

              <button
                className="absolute top-52 border-[2px] font-mono border-blue-700 rounded-tl-2xl rounded-br-2xl py-1 px-6"
                type="submit "
              >
                Submit{" "}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
