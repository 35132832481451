import React from "react";
import logo from "../../assets/Group.png"; 
import {
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const items1 = [
  { name: "Youtube", icon: FaYoutube, link: "https://youtube.com/" },
  { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/" },
  { name: "Twitter", icon: FaTwitter, link: "https://twitter.com/" },
  { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/" },
  { name: "Linkedin", icon: FaLinkedin, link: "https://www.linkedin.tv/" },
];

const CopyrightFooter = () => {
  return (
    <footer className="w-full bg-[#1473E6] text-white px-6 py-12 md:px-20">
      <div className="flex flex-col md:flex-row items-center justify-between">
        
        <div className="flex  items-center mb-8 md:mb-0">
          <img
            className="h-10 w-10 mr-4"
            src={logo}
            alt="Client Incorporation"
          />
          <div>
            <span className=" flex flex-col text-lg font-extrabold uppercase leading-none">
              CLIENT
            </span>
            
            <span className="flex flex-col text-lg font-normal leading-none">
              Incorporation
            </span>
          </div>
        </div>

        
        <div className="flex space-x-4 text-2xl">
          {items1.map((item, index) => (
            <a
              key={index}
              href={item.link}
              className="hover:text-gray-400"
              target="_blank"
              rel="noopener noreferrer"
            >
              <item.icon />
            </a>
          ))}
        </div>
      </div>

      <div className="my-8 border-t border-[#C1C7CD]"></div>

      <div className="flex flex-col md:flex-row items-center justify-between text-center">
        
        <p className="text-xs">ClientIncorporation @ 2024. All rights reserved.</p>

        
        <div className="flex space-x-4 text-sm mt-4 md:mt-0">
          <a href="#" className="hover:underline">
            Eleven
          </a>
          <a href="#" className="hover:underline">
            Twelve
          </a>
          <a href="#" className="hover:underline">
            Thirteen
          </a>
          <a href="#" className="hover:underline">
            Fourteen
          </a>
          <a href="#" className="hover:underline">
            Fifteen
          </a>
          <a href="#" className=" hover:underline">
            Sixteen
          </a>
        </div>
      </div>
    </footer>
  );
};

export default CopyrightFooter;

