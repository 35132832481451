import React from "react";
import "./Clientsay.css";
import ellipse129 from "../../assets/Ellipse.png";

const Clientsay = () => {
  return (
    <div className="clientsay-container">
      <div className="clientsay-content">
        <p className="quote-mark left-quote">
          {" "}
          <pre>“</pre>
        </p>
        <p className="clientsay-heading">
          WHAT OUR <span className="highlight">CLIENT</span> SAY'S
        </p>
        <div className="client-info">
          <img src={ellipse129} className="client-image" alt="Client" />
          <p className="client-name">Prakash Raj</p>
          <p className="client-title">CFO - Erecon India</p>
        </div>
        <div className="client-feedback">
          <p>
          The interface is incredibly user-friendly, allowing me to navigate effortlessly through various profiles and find companies that align perfectly with my needs. Whether I'm seeking services or partnerships, ClientIncorporation provides a seamless experience from start to finish.

What stands out the most is their personalized approach to matchmaking. Instead of sifting through endless options, ClientIncorporation streamlined the process, presenting me with tailored recommendations that match my criteria. This level of attention to detail not only saves time but also ensures that every interaction is meaningful and productive.
          </p>
        </div>
        <p className="quote-mark right-quote">
          {" "}
          <pre>”</pre>
        </p>
      </div>
    </div>
  );
};

export default Clientsay;
