import React from "react";
import Navbar from "./Navbar";
import Footer from "./components/Footer/Footer";
import CopyrightFooter from "./components/Footer/CopyrightFooter";

import SubscriptionPlans from "./components/SubscriptionPlans/SubsciptionPlans";
import Clientsay from "./components/Clientsay/Clientsay";
import OurWork from "./components/OurWork/OurWork";

function App() {
  return (
    <div>
      <Navbar />
      <OurWork />
      <SubscriptionPlans />
      <Clientsay />
      <Footer />
      <CopyrightFooter/>
    
      {/* Rest of your app components */}
    </div>
  );
}

export default App;
